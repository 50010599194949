import React, { useEffect } from 'react'
import textureGranite from '../../../Assets/texture-granite-about.jpg'
import about1 from '../../../Assets/about1.jpg'
import about2 from '../../../Assets/about2.jpg'
import './About.css'
//animation
import AOS from 'aos'
import 'aos/dist/aos.css'

const About = () => {
  useEffect(() => {
    AOS.init({duration: 2000})
  })
  return (
    <div style={{ backgroundImage: `url(${textureGranite})` }} className='bg-cover relative bg-about-sec' >
        <div data-aos="fade-up" className='w-5/6 relative z-10 m-auto flex justify-center items-center py-10 vsm:w-full vsm:flex-wrap sm:w-full sm:flex-wrap md:flex-nowrap lg:flex-nowrap lg:w-5/6'>
            <div className='w-3/6 vsm:w-11/12 sm:w-full md:w-3/6 lg:w-3/6'>
                <h3 className='text-xl font-semibold'>من نحن ؟</h3>
                <h2 className='text-5xl mt-3 mb-5 font-bold'>شركة جرانيت وطني</h2>
                <p className='text-xl font-medium mb-3'>شركة جرانيت وطني شركة سعودية ذات مسؤولية محدودة و مقرها الرئيسي المملكة العربية السعودية، مدينة الرياض.</p>
                <p className='text-xl font-medium mb-8'>يمتلك مصنع وطني خبرة في الصناعات الثقيلة استمرت لاكثر من 15 سنة في صناعات الجرانيت، ويعتبر مصنع وطني ثالث اكبر مصنع في المملكة من بين 15 مصنع متواجد في انحاء المملكة العربية السعودية.</p>
                <div className='w-auto text-right vsm:w-full sm:w-full md:w-full lg:w-auto vsm:text-center md:text-center lg:text-right'>
                  <a href="/من-نحن" className='font-bold relative bg-primary text-white px-8 py-3'>اعرف عنا اكثر</a>
                </div>
            </div>
            <div className='w-3/6 flex justify-center flex-col items-center vsm:w-full vsm:mt-10 sm:w-full md:w-3/6 lg:w-3/6' data-aos="fade-down">
                <img src={about1} alt='About US' className='shadow-2xl w-9/12' />
                <img src={about2} alt='About US' className='shadow-2xl w-6/12' style={{marginTop: '-62px'}} />
            </div>
        </div>
    </div>
  )
}

export default About