import React, { useEffect } from 'react'

//images
import certificate1 from '../../../Assets/Layer 2.png'
import certificate2 from '../../../Assets/Layer 3.png'
import certificate3 from '../../../Assets/Layer 4.png'
import certificate4 from '../../../Assets/Layer 5.png'
import certificate5 from '../../../Assets/Layer 6.png'
import certificate6 from '../../../Assets/Layer 7.png'
import certificate7 from '../../../Assets/Layer 8.png'
import certificate8 from '../../../Assets/Layer 9.png'
import certificate9 from '../../../Assets/Layer 10.png'
import certificate11 from '../../../Assets/Layer 12.png'

//sliderShow
import { Slide } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css';
//css style
import './Certificate.css'

//animation
import AOS from 'aos'
import 'aos/dist/aos.css'
const Certificate = () => {
    const certificats = [
        {
            id: 0,
            img: certificate1
        },
        {
            id: 1,
            img: certificate2
        },
        {
            id: 2,
            img: certificate3
        },
        {
            id: 3,
            img: certificate4
        },
        {
            id: 4,
            img: certificate5
        },
        {
            id: 5,
            img: certificate6
        },
        {
            id: 6,
            img: certificate7
        },
        {
            id: 7,
            img: certificate8
        },
        {
            id: 8,
            img: certificate9
        },
        {
            id: 9,
            img: certificate11
        },
    ]
    useEffect(() => {
        AOS.init({duration: 2000})
    })
  return (
    <div className='py-14'>
        <div>
            <h2 className='text-center font-bold text-5xl mb-2' data-aos="fade-up">شهاداتنا</h2>
            <h5 className='text-center font-normal text-xl' data-aos="fade-up">امتنان شركة جرانيت وطني لثقة المملكة العربية السعودية فيها للنهوض بهذا المجال</h5>
            <div className='flex flex-row m-auto w-4/5 mt-10 items-center justify-center vsm:hidden sm:hidden md:hidden lg:flex'>
                {
                    certificats.map((data, index)=>(
                        <div className='border border-primary'>
                            <img src={data.img} alt='certificate' className='w-32 object-cover grayscale img-certifice hover:w-72' style={{height: '484px'}}  />
                        </div>
                    ))
                }
            </div>
            <div className="mt-4 hidden vsm:block sm:block md:block lg:hidden" data-aos="fade-up">
                <Slide>              
                    {certificats.map((data, index) => (
                        <div key={data.id} className='each-slide-effect w-4/5 m-auto'>
                            <img src={data.img} className='img-place' />
                        </div>
                    ))}
                </Slide>
            </div>
        </div>
    </div>
  )
}

export default Certificate