import React, { Component } from 'react'
import {
    BrowserRouter as Router,
    Routes,
    Route,
} from "react-router-dom";
import { Home, Contact, Products, Product, About, Projects, Error, FormOrder } from '../index'
class Navigation extends Component {
    render() {
        return (
            <Router>
                <Routes>
                    <Route
                        exact
                        path="/"
                        element={<Home />}
                    ></Route>
                    <Route
                        exact
                        path="/تواصل-معنا"
                        element={<Contact />}
                    ></Route>
                    <Route
                        exact
                        path="/المنتجات"
                        element={<Products />}
                    ></Route>
                    <Route
                        exact
                        path="/منتج/"
                        element={<Product />}
                    ></Route>
                    <Route
                        exact
                        path="/من-نحن"
                        element={<About />}
                    ></Route>
                    <Route
                        exact
                        path="/مشاريعنا"
                        element={<Projects />}
                    ></Route>
                    <Route
                        exact
                        path="/منتج/اتمام-الطلب"
                        element={<FormOrder />}
                    ></Route>
                    <Route
                        exact
                        path="*"
                        element={<Error />}
                    ></Route>
                </Routes>
            </Router>
        );
    }
}

export default Navigation