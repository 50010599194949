import React, { useState, useEffect } from 'react'
import granite from '../../../Assets/granite.jpg'
import granite1 from '../../../Assets/granite1.jpg'
import granite2 from '../../../Assets/granite2.jpg'
//css style
import './slide.css'
//animation
import AOS from 'aos'
import 'aos/dist/aos.css'

const SlideHeader = () => {
    const [currentIndex, setCurrentIndex] = useState(0);

    const imgs = [
        {
            id: 0,
            img: granite
        },
        {
            id: 1,
            img: granite1
        },
        {
            id: 2,
            img: granite2
        },
    ] 
    useEffect(() => {
        const intervalId = setInterval(() => {
          setCurrentIndex((prevIndex) =>
            prevIndex === imgs.length - 1 ? 0 : prevIndex + 1
          );
        }, 5000);
        // Clean up the interval when the component unmounts
        return () => clearInterval(intervalId);
    }, [imgs.length]);
    useEffect(() => {
        AOS.init({duration: 2000})
    })
  return (
    <div className='slide-height relative object-contain w-full bg-fixed slide-animation'>
        <div
          className='background-image'
          style={{ backgroundImage: `url(${imgs[currentIndex].img})` }}
        ></div>        
        <div className='text-white w-4/5 m-auto text-center flex flex-col items-center justify-center h-full relative z-10'>
            <h2 className='font-bold text-6xl mb-5 vsm:text-4xl sm:text-4xl md:text-5xl lg:text-6xl drop-shadow-2xl' data-aos="fade-up" style={{textShadow: '-1px 0px 10px #000000'}}>مصنع جرانيت وطني</h2>
            <p className='text-1xl mb-5 w-4/6 font-medium vsm:w-4/5 sm:w-4/5 md:w-4/6' data-aos="fade-up" style={{textShadow: '-1px 0px 10px #000000'}}>تقديم خدمات مميزة في مجال الرخام و الجرانيت تساهم في خدمة المجتمع من خلال ايجاد بيئة محفزة لتقديم الخدمات الامثل و الافضل لزبنائنا </p>
            <div className='flex flex-wrap justify-center items-center gap-5'>
                <button className='bg-primary relative text-white font-bold px-9 py-3 ml-9 btn-learn-more vsm:ml-0 sm:ml-0 md:ml-9 vsm:w-3/4 md:w-auto' data-aos="fade-up">
                    <a href="/من-نحن" className='relative z-10'>اعرف اكثر</a>
                </button>
                <button className='bg-white relative text-black font-bold px-9 py-3 btn-contact-slide vsm:w-3/4 md:w-auto' data-aos="fade-up">
                    <a href="/تواصل-معنا" className='relative z-10'>تواصل معنا</a>
                </button>
            </div>
        </div>
    </div>
  )
}

export default SlideHeader