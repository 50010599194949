const ProductData = [
    {
        id: 0,
        title: 'بيانكو سعودي',
        price: '٥٩',
        realPrice: 59,
        image: require('../../Assets/product/biyanko.jpg'),
        type: 'granite',
        country: 'السعودية',
        poi: '30 x 60',
        paragraph: [
            {
                id: 0,
                p: 'جرانيت بيانكو السعودي يعتبر خياراً رائعاً لمن يبحث عن الجمال والفخامة في التصميم الداخلي والخارجي. هذا النوع من الجرانيت يتميز بألوانه المتناسقة والمميزة التي تضفي لمسة من الأناقة والرقي على المساحات. يعتبر مثالياً للاستخدام في المطابخ، الحمامات، الأرضيات والجدران نظراً لجماله ولونه الأبيض الهادئ.'
            },
            {
                id: 1,
                p: 'ما يميز جرانيت بيانكو السعودي عن غيره هو قوته وصلابته، بالإضافة إلى قدرته على تحمل حرارة الشمس، مما يجعله مثالياً للاستخدام في التصميمات الخارجية وأسطح المطابخ والكونترات. استخدام هذا النوع من الجرانيت يضمن إضافة لمسة من الفخامة والأناقة لأي مشروع، مما يجعله خياراً مفضلاً في العديد من المشاريع العمرانية.'
            }
        ],       
        details: [
            {
                id: 0,
                name: 'صناعة سعودية'
            },
            {
                id: 1,
                name: 'جودة و مواصفات عالية'
            },
            {
                id: 2,
                name: "مقاومة الماء و الرطوبة"
            },
            {
                id: 3,
                name: "سهولة التنظيف"
            }
        ]
    },
    {
        id: 1,
        title: 'بيانكو سعودي',
        price: '٦٩',
        realPrice: 59,
        image: require('../../Assets/product/biyanko.jpg'),
        type: 'granite',
        country: 'السعودية',
        poi: '60 x 60',
        paragraph: [
            {
                id: 0,
                p: 'جرانيت بيانكو السعودي يعتبر خياراً رائعاً لمن يبحث عن الجمال والفخامة في التصميم الداخلي والخارجي. هذا النوع من الجرانيت يتميز بألوانه المتناسقة والمميزة التي تضفي لمسة من الأناقة والرقي على المساحات. يعتبر مثالياً للاستخدام في المطابخ، الحمامات، الأرضيات والجدران نظراً لجماله ولونه الأبيض الهادئ.'
            },
            {
                id: 1,
                p: 'ما يميز جرانيت بيانكو السعودي عن غيره هو قوته وصلابته، بالإضافة إلى قدرته على تحمل حرارة الشمس، مما يجعله مثالياً للاستخدام في التصميمات الخارجية وأسطح المطابخ والكونترات. استخدام هذا النوع من الجرانيت يضمن إضافة لمسة من الفخامة والأناقة لأي مشروع، مما يجعله خياراً مفضلاً في العديد من المشاريع العمرانية.'
            }
        ],
        details: [
            {
                id: 0,
                name: 'صناعة سعودية'
            },
            {
                id: 1,
                name: 'جودة و مواصفات عالية'
            },
            {
                id: 2,
                name: "مقاومة الماء و الرطوبة"
            },
            {
                id: 3,
                name: "سهولة التنظيف"
            }
        ]
    },
    {
        id: 2,
        title: 'درج بيانكو سعودي',
        price: '٤٩',
        realPrice: 49,
        image: require('../../Assets/product/biyanko.jpg'),
        type: 'granite',
        country: 'السعودية',
        poi: 'درج',
        paragraph: [
            {
                id: 0,
                p: 'جرانيت بيانكو السعودي يعتبر خياراً رائعاً لمن يبحث عن الجمال والفخامة في التصميم الداخلي والخارجي. هذا النوع من الجرانيت يتميز بألوانه المتناسقة والمميزة التي تضفي لمسة من الأناقة والرقي على المساحات. يعتبر مثالياً للاستخدام في المطابخ، الحمامات، الأرضيات والجدران نظراً لجماله ولونه الأبيض الهادئ.'
            },
            {
                id: 1,
                p: 'ما يميز جرانيت بيانكو السعودي عن غيره هو قوته وصلابته، بالإضافة إلى قدرته على تحمل حرارة الشمس، مما يجعله مثالياً للاستخدام في التصميمات الخارجية وأسطح المطابخ والكونترات. استخدام هذا النوع من الجرانيت يضمن إضافة لمسة من الفخامة والأناقة لأي مشروع، مما يجعله خياراً مفضلاً في العديد من المشاريع العمرانية.'
            }
        ],        details: [
            {
                id: 0,
                name: 'صناعة سعودية'
            },
            {
                id: 1,
                name: 'جودة و مواصفات عالية'
            },
            {
                id: 2,
                name: "مقاومة الماء و الرطوبة"
            },
            {
                id: 3,
                name: "سهولة التنظيف"
            }
        ]
    },
    {
        id: 3,
        title: 'نجران براون',
        price: '٧٩',
        realPrice: 79,
        image: require('../../Assets/product/nijranBrown.jpg'),
        type: 'granite',
        country: 'السعودية',
        poi: '30 x 60',
        paragraph: [
            {
                id: 0,
                p: 'هذا النوع من الجرانيت يُعتبر واحدًا من أكثر أنواع الجرانيت الفاخرة والجميلة. يتميز بلونه البني الداكن اللامع الذي يُضفي عليه طابعًا استثنائيًا. كما يتميز بنقوشه المميزة والمبهجة التي تُضفي على الجرانيت لمسة من الحياة والحركة.'
            },
            {
                id: 1,
                p: 'يمكن استخدام هذا الجرانيت بشكل رائع في مختلف المواقع، سواء داخل المنازل لتعزيز الأثاث والديكور بشكل جميل، أو داخل المنشآت التجارية لإضافة لمسة من الفخامة والأناقة. بفضل تصميمه الفريد ولونه البني الجذاب، يتألق هذا الجرانيت في أي مكان يتم تطبيقه فيه، مما يجعله'
            }
        ],
        details: [
            {
                id: 0,
                name: 'صناعة سعودية'
            },
            {
                id: 1,
                name: 'جودة و مواصفات عالية'
            },
            {
                id: 2,
                name: "مقاومة الماء و الرطوبة"
            },
            {
                id: 3,
                name: "سهولة التنظيف"
            }
        ]
    },
    {
        id: 4,
        title: 'نجران براون',
        price: '٨٩',
        realPrice: 89,
        image: require('../../Assets/product/nijranBrown.jpg'),
        type: 'granite',
        country: 'السعودية',
        poi: '60 x 60',
        paragraph: [
            {
                id: 0,
                p: 'هذا النوع من الجرانيت يُعتبر واحدًا من أكثر أنواع الجرانيت الفاخرة والجميلة. يتميز بلونه البني الداكن اللامع الذي يُضفي عليه طابعًا استثنائيًا. كما يتميز بنقوشه المميزة والمبهجة التي تُضفي على الجرانيت لمسة من الحياة والحركة.'
            },
            {
                id: 1,
                p: 'يمكن استخدام هذا الجرانيت بشكل رائع في مختلف المواقع، سواء داخل المنازل لتعزيز الأثاث والديكور بشكل جميل، أو داخل المنشآت التجارية لإضافة لمسة من الفخامة والأناقة. بفضل تصميمه الفريد ولونه البني الجذاب، يتألق هذا الجرانيت في أي مكان يتم تطبيقه فيه، مما يجعله'
            }
        ],
        details: [
            {
                id: 0,
                name: 'صناعة سعودية'
            },
            {
                id: 1,
                name: 'جودة و مواصفات عالية'
            },
            {
                id: 2,
                name: "مقاومة الماء و الرطوبة"
            },
            {
                id: 3,
                name: "سهولة التنظيف"
            }
        ]
    },
    {
        id: 5,
        title: 'نجران تروبيكال',
        price: '٧٩',
        realPrice: 79,
        image: require('../../Assets/product/nijranTrobikal.jpeg'),
        type: 'granite',
        country: 'السعودية',
        poi: '30 x 60',
        paragraph: [
            {
                id: 0,
                p: 'استمتع بسحر جرانيت نجران تروبيكال الفريد، الذي يتميز بلونه البني الغامق والنقاط المتراصة بأناقة فائقة على سطحه. هذا الجرانيت الرائع يسعى ليكون فئة خاصة من جرانيت الديكور الأسود، مما يضفي لمسة من الفخامة على مطبخك. يُمكن استخدامه بتأثير رائع كأدراج، وأرضيات، وحتى في تصميمات الديكور الداخلي والخارجي. اجعل منزلك يتألق بفخامة هذا اللون الراقي وزِد من رونق ديكورك الحديث.'
            },
        ],
        details: [
            {
                id: 0,
                name: 'صناعة سعودية'
            },
            {
                id: 1,
                name: 'جودة و مواصفات عالية'
            },
            {
                id: 2,
                name: "مقاومة الماء و الرطوبة"
            },
            {
                id: 3,
                name: "سهولة التنظيف"
            }
        ]
    },
    {
        id: 6,
        title: 'درج نجران براون',
        price: '٦٩',
        realPrice: 69,
        image: require('../../Assets/product/nijranBrown.jpg'),
        type: 'granite',
        country: 'السعودية',
        poi: 'درج',
        paragraph: [
            {
                id: 0,
                p: 'يعتبر أحد الأصناف المميزة من الجرانيت يتألق بألوان جذابة حيث يتميز بلون أرضية بنية مع نقوش ناعمة ومتنوعة على سطحه من اللون الاسود'
            },
            {
                id: 1,
                p: 'بالإضافة لجماله فهو يتميز بقوة ومتانة عالية، له قدرة عالية على تحمل حرارة الشمس والتآكل والعوامل الخارجية الاخرى لذا ينصح باستخدامه في التصميم الخارجي على وجه العموم وأسطح المطابخ والكونترات أيضاً'
            }
        ],
        details: [
            {
                id: 0,
                name: 'صناعة سعودية'
            },
            {
                id: 1,
                name: 'جودة و مواصفات عالية'
            },
            {
                id: 2,
                name: "مقاومة الماء و الرطوبة"
            },
            {
                id: 3,
                name: "سهولة التنظيف"
            }
        ]
    },
   
    {
        id: 7,
        title: 'رويال روز',
        price: '٨٠',
        realPrice: 80,
        image: require('../../Assets/product/roz.jpg'),
        type: 'granite',
        country: 'السعودية',
        poi: '30 x 60',
        paragraph: [
            {
                id: 0,
                p: 'هو أحد أنواع الجرانيت الوطني المميز، هو صنف أنيق من الجرانيت يتميز بلون ابيض رمادي ونقوش ناعمة ومتعددة تجعله يشبه رمال البحر.'
            },
            {
                id: 1,
                p: 'يعتبر رويال روز مثالياً للاستخدام في التصميم الخارجي وكونترات المطابخ نظراً لصلابته وقوته العالية وقدرته على مقاومة العوامل الخارجية مثل حرارة الشمس والتآكل وغيرها.'
            }
        ],
        details: [
            {
                id: 0,
                name: 'صناعة سعودية'
            },
            {
                id: 1,
                name: 'جودة و مواصفات عالية'
            },
            {
                id: 2,
                name: "مقاومة الماء و الرطوبة"
            },
            {
                id: 3,
                name: "سهولة التنظيف"
            }
        ]
    },
    {
        id: 8,
        title: 'درج رويال روز',
        price: '٦٠',
        realPrice: 60,
        image: require('../../Assets/product/roz.jpg'),
        type: 'granite',
        country: 'السعودية',
        poi: 'درج',
        paragraph: [
            {
                id: 0,
                p: 'هو أحد أنواع الجرانيت الوطني المميز، هو صنف أنيق من الجرانيت يتميز بلون ابيض ونقوش ناعمة ومتعددة تجعله يشبه رمال البحر.'
            },
            {
                id: 1,
                p: 'يعتبر رويال روز مثالياً للاستخدام في التصميم الخارجي وكونترات المطابخ نظراً لصلابته وقوته العالية وقدرته على مقاومة العوامل الخارجية مثل حرارة الشمس والتآكل وغيرها.'
            }
        ],
        details: [
            {
                id: 0,
                name: 'صناعة سعودية'
            },
            {
                id: 1,
                name: 'جودة و مواصفات عالية'
            },
            {
                id: 2,
                name: "مقاومة الماء و الرطوبة"
            },
            {
                id: 3,
                name: "سهولة التنظيف"
            }
        ]
    },
    {
        id: 9,
        title: 'رويال قولد',
        price: '٩٠',
        realPrice: 90,
        image: require('../../Assets/product/gold.jpg'),
        type: 'granite',
        country: 'السعودية',
        poi: '30 x 60',
        paragraph: [
            {
                id: 0,
                p: 'هو أحد أنواع الجرانيت الطبيعي ذو الوان غنية  وجذابة يتميز بتدرّجات ذهبية محاطة بالاسود و الابيض يجعله ساحرا في مظهره . يعتبر هذا الجرانيت من أكثر أنواع الجرانيت شهرة وطلباً في عالم التصميم والبناء نظراً لجماله وقوته المتينة'
            },
            {
                id: 1,
                p: 'يتميز بمقاومته العالية للخدوش والتآكل والحرارة، مما يجعله خيار مثالي للتصميم الخارجي وكونترات المطابخ، كما يستخدم في المطابخ والحمامات والأرضيات والجدران.'
            }
        ],
        details: [
            {
                id: 0,
                name: 'صناعة سعودية'
            },
            {
                id: 1,
                name: 'جودة و مواصفات عالية'
            },
            {
                id: 2,
                name: "مقاومة الماء و الرطوبة"
            },
            {
                id: 3,
                name: "سهولة التنظيف"
            }
        ]
    },
    {
        id: 10,
        title: 'درج رويال قولد',
        price: '٨٠',
        realPrice: 80,
        image: require('../../Assets/product/gold.jpg'),
        type: 'granite',
        country: 'السعودية',
        poi: 'درج',
        paragraph: [
            {
                id: 0,
                p: 'هو أحد أنواع الجرانيت الطبيعي ذو الوان غنية  وجذابة يتميز بتدرّجات ذهبية محاطة بالاسود و الابيض يجعله ساحرا في مظهره . يعتبر هذا الجرانيت من أكثر أنواع الجرانيت شهرة وطلباً في عالم التصميم والبناء نظراً لجماله وقوته المتينة'
            },
            {
                id: 1,
                p: 'يتميز بمقاومته العالية للخدوش والتآكل والحرارة، مما يجعله خيار مثالي للتصميم الخارجي وكونترات المطابخ، كما يستخدم في المطابخ والحمامات والأرضيات والجدران.'
            }
        ],
        details: [
            {
                id: 0,
                name: 'صناعة سعودية'
            },
            {
                id: 1,
                name: 'جودة و مواصفات عالية'
            },
            {
                id: 2,
                name: "مقاومة الماء و الرطوبة"
            },
            {
                id: 3,
                name: "سهولة التنظيف"
            }
        ]
    },
    {
        id: 11,
        title: 'رويال جري',
        price: '٩٠',
        realPrice: 90,
        image: require('../../Assets/product/jri.jpeg'),
        type: 'granite',
        country: 'السعودية',
        poi: '30 x 60',
        paragraph: [
            {
                id: 0,
                p: 'هو اقرب انواع الجرانيت لجمال الطبيعة . يتميز بلون أبيض  ونقوش ناعمة بلون اسود واخرى بلون رمادي تنمزج مع بعضها لتشكل عمل فني طبيعي'
            },
            {
                id: 1,
                p: 'كما يعد مثالي للاستخدام في المباني والتصميم الداخلي وتجهيزات المطابخ والحمامات، وأيضاً في الأرضيات والجدران والمداخل'
            }
        ],
        details: [
            {
                id: 0,
                name: 'صناعة سعودية'
            },
            {
                id: 1,
                name: 'جودة و مواصفات عالية'
            },
            {
                id: 2,
                name: "مقاومة الماء و الرطوبة"
            },
            {
                id: 3,
                name: "سهولة التنظيف"
            }
        ]
    },
    {
        id: 12,
        title: 'درج رويال جري',
        price: '٨٠',
        realPrice: 80,
        image: require('../../Assets/product/jri.jpeg'),
        type: 'granite',
        country: 'السعودية',
        poi: 'درج',
        paragraph: [
            {
                id: 0,
                p: 'هو اقرب انواع الجرانيت لجمال الطبيعة . يتميز بلون أبيض  ونقوش ناعمة بلون اسود واخرى بلون رمادي تنمزج مع بعضها لتشكل عمل فني طبيعي'
            },
            {
                id: 1,
                p: 'كما يعد مثالي للاستخدام في المباني والتصميم الداخلي وتجهيزات المطابخ والحمامات، وأيضاً في الأرضيات والجدران والمداخل'
            }
        ],
        details: [
            {
                id: 0,
                name: 'صناعة سعودية'
            },
            {
                id: 1,
                name: 'جودة و مواصفات عالية'
            },
            {
                id: 2,
                name: "مقاومة الماء و الرطوبة"
            },
            {
                id: 3,
                name: "سهولة التنظيف"
            }
        ]
    },
]

export default ProductData;