import React, {useEffect} from 'react'
//images
import client1 from '../../../Assets/client1.png'
import client2 from '../../../Assets/client2.png'
import client3 from '../../../Assets/client3.png'
import client4 from '../../../Assets/client4.png'
import client5 from '../../../Assets/client5.png'
import client6 from '../../../Assets/client6.webp'
import client7 from '../../../Assets/client7.png'
import client8 from '../../../Assets/client8.png'
import client9 from '../../../Assets/client9.png'
import client10 from '../../../Assets/client10.png'

import bgClients from '../../../Assets/association.jpg'


//animation
import AOS from 'aos'
import 'aos/dist/aos.css'

const Clients = () => {
    const clients = [
        {
            id: 0,
            img: client1
        },
        {
            id: 1,
            img: client2
        },
        {
            id: 2,
            img: client3
        },
        {
            id: 3,
            img: client4
        },
        {
            id: 4,
            img: client5
        },
        {
            id: 5,
            img: client6
        },
        {
            id: 6,
            img: client7
        },
        {
            id: 7,
            img: client8
        },
        {
            id: 8,
            img: client9
        },
        {
            id: 9,
            img: client10
        },
    ]
    const CilentCompanyName = [
        {
            id: 1,
            name: 'قطار المشاعر في مكة'
        },
    ]
    useEffect(() => {
        AOS.init({duration: 2000})
    })
  return (
    <>
        <div className='bg-primary py-20 vsm:hidden sm:hidden md:hidden lg:block' id='success-partners'>
            <div className='' data-aos="fade-up">
                <h2 className='text-center font-bold text-5xl mb-2'>شركاء النجاح</h2>
                <div className='flex flex-wrap justify-center gap-10 w-9/12 m-auto mt-10 vsm:w-11/12 sm:w-11/12 md:w-11/12 lg:w-9/12'>
                    {
                        clients.map((data, index)=>(
                            <div key={index}>
                                <img src={data.img} alt='Clients' className='h-32 w-full object-contain' />
                            </div>
                        ))
                    }
                </div>
                <div className='flex flex-wrap justify-center gap-10 w-9/12 m-auto mt-12 vsm:w-11/12 sm:w-11/12 md:w-11/12 lg:w-9/12'>
                    {
                        CilentCompanyName.map((data, index)=>(
                            <p key={index} className='text-2xl'>{data.name}</p>
                        ))
                    }
                </div>
            </div>
        </div>
        <img src={bgClients} alt='' className='w-full vsm:block sm:block md:block lg:hidden' />
    </>
  )
}

export default Clients