import React, {useState, useEffect} from 'react'
import { FaCartShopping } from "react-icons/fa6";
import './Products.css'
import textures from '../../../Assets/abstractbackground.jpg'
import ProductData from '../../data/ProductData';
//animation
import AOS from 'aos'
import 'aos/dist/aos.css'

const Products = () => {
    const [products, setproducts] = useState(ProductData)
    useEffect(() => {
        AOS.init({duration: 2000})
    })
  return (
    <div style={{ backgroundImage: `url(${textures})` }} className='py-10 bg-pro'>
        <div className='relative z-10'>
            <h1 className='text-center font-bold text-5xl mb-2' data-aos="fade-up">منتجاتنا</h1>
            <h5 className='text-center font-normal text-xl w-4/5 m-auto' data-aos="fade-up">تختص شركة جرانيت وطني بتوفير اجود اصناف و انواع الرخام</h5>
            <div className='flex flex-wrap justify-center items-start w-9/12 m-auto mt-10 gap-5 vsm:w-11/12 sm:w-11/12 md:w-11/12 lg:w-9/12'>
                {
                    products.map((data, index)=>(
                        index <= 7? (
                            <div key={index} data-aos="zoom-in" className='cursor-pointer shadow-md mb-8 relative z-10 rounded-2xl w-1/5 vsm:w-2/5 sm:w-2/5 md:w-2/5 lg:w-1/5 hover-product'>
                                <div className='relative'>
                                    <div className='w-full m-auto flex justify-center items-center rounded-t-2xl' style={{backgroundColor: '#00000030'}}>
                                        <img src={data.image} alt={data.title} className='h-56 w-full object-cover shadow-lg rounded-t-2xl vsm:h-36 sm:h-36 md:h-40 lg:h-56' />
                                    </div>
                                    <div className=''>
                                        <div className='border-b p-5 flex items-center justify-between flex-wrap'>
                                            <h2 className='text-center font-medium text-xl vsm:text-sm vsm:text-right sm:text-sm sm:text-right md:text-sm md:text-right lg:text-center lg:text-xl'>{data.title}</h2>
                                            <p  style={{ direction: 'ltr' }} className='font-medium text-xl vsm:text-sm vsm:text-right vsm:font-bold sm:font-bold sm:text-sm sm:text-right md:text-sm md:text-right lg:text-center lg:text-xl'>{data.poi === 'درج'? "": data.poi}</p>
                                        </div>
                                        <div className='flex justify-around items-center'>
                                            <p className='text-primary font-medium text-center text-xl py-5 w-3/5 border-l vsm:text-base sm:text-base md:text-xl'>{data.price} ر.س</p>
                                            <FaCartShopping className='text-xl w-1/4 cursor-pointer vsm:text-base sm:text-base md:text-xl' />
                                        </div>
                                    </div>
                                    <a className='text-primary font-bold' href={`/منتج/?id=${index}`}>
                                        <button className='btn-order scale-0 absolute z-10 border px-14 py-3 bg-white rounded-lg cursor-pointer w-4/5 text-base vsm:p-3 sm:p-3 md:p-3'>اطلب الان</button>
                                    </a>
                                </div>
                            </div>
                        ) :(
                            null
                        )
                    ))
                }
            </div>
            <button className='flex justify-center items-center m-auto px-8 py-3 border-2 cursor-pointer border-primary btn-show-more'>
                <a href="/المنتجات">انظر المزيد</a>
            </button>
        </div>
    </div>
  )
}

export default Products