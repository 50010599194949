import React, { useEffect } from 'react'
import Header from '../Home/Header/Header'
import Footer from '../Home/Footer/Footer'

const Error = () => {
    useEffect(() => {
      document.title = 'هذه الصفحة موجودة - مصنع جرانيت وطني'
    })
  return (
    <>
        <Header />
        <div className='py-10 text-center'>
            <div className='py-10'>
                <h3 className='text-primary text-7xl font-bold mb-5'>لا يبدو ان هذه الصفحة موجودة</h3>
                <p className='text-2xl font-medium mb-5' style={{color: '#cdcdcd'}}>يبدو ان الرابط الذي يشير هنا خاطئ جرب مرة اخرى</p>
                <h2 className='text-9xl font-bold text-primary'>404</h2>
            </div>
        </div>
        <Footer />
    </>
  )
}

export default Error