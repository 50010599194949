import React, { useState, useEffect } from 'react'
import project from '../../../Assets/project.jpg'
import { FaArrowLeftLong } from "react-icons/fa6";
import textureGranite from '../../../Assets/texture-granite-about.jpg'
import './Projects.css'
import ProjectsData from '../../data/ProjectsData';
//animation
import AOS from 'aos'
import 'aos/dist/aos.css'

const Projects = () => {
    const [projects, setprojects] = useState(ProjectsData)
    useEffect(() => {
        AOS.init({duration: 2000})
    })
  return (
    <div style={{ backgroundImage: `url(${textureGranite})` }} className='py-10 relative bg-project'>
        <div className='relative z-10'>
            <h2 className='text-center font-bold text-5xl mb-2' data-aos="fade-up">مشاريعنا</h2>
            <h5 className='text-center font-normal text-xl' data-aos="fade-up">تفتخر شركة جرانيت وطني بالعمل مع عدة مشاريع</h5>
            <div className='flex flex-col justify-center items-start w-9/12 m-auto mt-10 gap-5 vsm:w-11/12 sm:w-11/12 md:w-11/12 lg:w-9/12'>
                {
                    projects.map((data, index)=>(
                        index <= 2? (
                            index%2 === 0? (
                                <div data-aos="zoom-in" className='bg-primary flex flex-nowrap flex-row justify-center items-center vsm:flex-wrap sm:flex-wrap md:flex-wrap lg:flex-nowrap'>
                                    <div className='w-1/2 vsm:w-full sm:w-full md:w-full xl:w-1/2'>
                                        <img src={data.img} alt={data.title} className='h-80 object-cover vsm:w-full sm:w-full md:w-full xl:w-full' />
                                    </div>
                                    <div className='h-full w-1/2 vsm:w-full sm:w-full md:w-full xl:w-1/2 flex flex-col gap-7 items-start p-6'>
                                        <h2 className='font-bold text-2xl '>{data.title}</h2>
                                        <p className='text-lg'>{data.description}</p>
                                        <a href={data.url} target='_blanck'>
                                            <button className='flex flex-row items-center gap-5 bg-white px-6 py-3 text-primary rounded-sm'>اعرف عنا اكثر <FaArrowLeftLong /></button>
                                        </a>
                                    </div>
                                </div>
                            ):(
                                <div data-aos="zoom-in" className='bg-second flex flex-nowrap flex-row justify-center items-center vsm:flex-wrap sm:flex-wrap md:flex-wrap lg:flex-nowrap'>
                                    <div className='w-1/2 vsm:w-full sm:w-full md:w-full xl:w-1/2'>
                                        <img src={data.img} alt={data.title} className='h-80 object-cover vsm:w-full sm:w-full md:w-full xl:w-full' />
                                    </div>
                                    <div className='h-full w-1/2 vsm:w-full sm:w-full md:w-full xl:w-1/2 flex flex-col gap-7 items-start p-6'>
                                        <h2 className='font-bold text-2xl text-white'>{data.title}</h2>
                                        <p className='text-lg text-white'>{data.description}</p>
                                        <a href={data.url} target='_blanck'>
                                            <button className='flex flex-row items-center gap-5 bg-white px-6 py-3 text-second rounded-sm'>اعرف عنا اكثر <FaArrowLeftLong /></button>
                                        </a>
                                    </div>
                                </div>
                            )
                        ) : (
                            null
                        )
                    ))
                }
            </div>
            <a href="/مشاريعنا">
                <button className='flex justify-center items-center m-auto px-8 py-3 border-2 cursor-pointer border-primary btn-show-more mt-10'>انظر المزيد</button>
            </a>
        </div>
    </div>
  )
}

export default Projects