import React from 'react'
import Header from './Header/Header'
import SlideHeader from './Slide/Slide'
import About from './About/About'
import Product from './Products/Products'
import Projects from './Projects/Projects'
import Certificate from './Certificate/Certificate'
import Clients from './Clients/Clients'
import Footer from './Footer/Footer'

const Home = () => {
  return (
    <>
        <Header />
        <SlideHeader />
        <About />
        <Product />
        <Projects />
        <Certificate />
        <Clients />
        <Footer />
    </>
  )
}

export default Home