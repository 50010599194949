import React, { useEffect, useState } from 'react'
import Header from '../Home/Header/Header'
import Footer from '../Home/Footer/Footer'
import place from '../../Assets/watani-place.jpeg'
import placeAddress from '../../Assets/placeAddress.jpg'
import './Contact.css'
import { 
    FaLocationDot,
    FaPhoneFlip,
    FaEnvelope,
    FaMapLocationDot
} from "react-icons/fa6";
//animation
import AOS from 'aos'
import 'aos/dist/aos.css'
const Contact = () => {
    useEffect(() => {
        document.title = 'تواصل معنا - مصنع جرانيت وطني'
    })
    const [FullName, setFullName] = useState('')
    const [email, setemail] = useState('')
    const [message, setmessage] = useState('')
    const [ErrorEmpty, setErrorEmpty] = useState('')
    const handleSubmit = async (e) => {
        e.preventDefault();
        if(FullName === '' || message === ''){
            setErrorEmpty('يرجى إكمال جميع الحقول المطلوبة قبل إرسال النموذج.')
        } else {
            const formData = new FormData();
            formData.append('fullName', FullName);
            formData.append('Email', email);
            formData.append('Message', message);
            formData.append('from', "contactForm");
            try{
                const response = await fetch('https://www.granitewatani.com/send-email.php', {
                    method: 'POST',
                    body: formData,
                });
                const json = await response.json();
                if (json.success) {
                    alert('تم إرسال الطلب بنجاح سوف يتم التواصل معكم في أقرب وقت ممكن')
                    setFullName('')
                    setemail('')
                    setmessage('')
                    setErrorEmpty('')
                } else {
                  console.log(json.success);
                }
            } catch(error){
                console.log(error)
            }
        }
    };
    useEffect(() => {
        AOS.init({duration: 2000})
    })
  return (
    <>
        <Header />
        <div className='pt-10'>
            <div>
                <h2 className='text-center text-4xl font-bold' data-aos="fade-up">تواصل معنا</h2>
                <p className='text-center font-bold py-2' data-aos="fade-up">يمكن لك ان تتواصل معنا بسهولة على:</p>
                <div className='flex justify-center items-center flex-wrap'  data-aos="fade-up">
                    <a href='tel:+996541177132' className='flex items-center gap-2 pr-2 pb-2 font-bold'><FaPhoneFlip />996541177132+</a>
                </div>
                <div className='flex justify-center items-center flex-wrap'  data-aos="fade-up">
                    <a href='tel:+996531442644' className='flex items-center gap-2 pr-2 pb-2 font-bold'><FaPhoneFlip />996531442644+</a>
                </div>
                <div className='text-center flex justify-center'>
                    <a href='https://maps.app.goo.gl/YpNWYVYygqJL26F1A' className='text-primary flex items-center gap-2 font-bold' target='_blanck'><FaMapLocationDot  />موقعنا على الخريطة</a>
                </div>
                <div className='flex flex-nowrap w-4/5 m-auto justify-center gap-10 mt-12 vsm:flex-wrap vsm:w-full sm:flex-wrap sm:w-full md:flex-wrap md:w-full lg:flex-nowrap lg:w-4/5'>
                    <div className='w-1/2 vsm:w-4/5 sm:w-4/5 md:w-4/5 lg:w-1/2' data-aos="fade-left">
                        <h4 className='font-bold text-xl mb-2'>اترك لنا طلبك</h4>
                        <p className=''>يمكنك ان ترسل طلبك او اي تساؤل عبر تعبئة النموذج التالي وسوف نرد عليك في اسرع وقت ممكن</p>
                        <h4 className='font-bold text-xl mt-5 mb-2'>يمكنك ايضا</h4>
                        <p>ان تزورنا في مقرنا في المنطقة الصناعية ضرما في الرياض</p>
                        <img src={place} alt="شركة جرانيت وطني" className='h-80 w-full object-cover m-auto mt-5' />
                    </div>
                    <div className='w-1/2 vsm:w-4/5 sm:w-4/5 md:w-4/5 lg:w-1/2' data-aos="fade-left">
                        <form method='POST'>
                            <div className='flex flex-col mb-5'>
                                <label for='full-name' className='mb-2'>الاسم الكامل<span className='pr-2' style={{ color: 'red' }}>*</span></label>
                                <input type='text' id='full-name' name='fullName' onChange={(e)=> setFullName(e.target.value)} value={FullName} className='border w-full px-3 py-2 outline-0' required/>
                            </div>
                            <div className='flex flex-col mb-5'>
                                <label for='Email' className='mb-2'>البريد الالكتروني</label>
                                <input type='email' id='Email' name='email' onChange={(e)=> setemail(e.target.value)} value={email} className='border w-full px-3 py-2 outline-0' />
                            </div>
                            <div className='flex flex-col mb-5'>
                                <label for='message' className='mb-2'>نص الرسالة<span className='pr-2' style={{ color: 'red' }}>*</span></label>
                                <textarea id='message' name='message' onChange={(e)=> setmessage(e.target.value)} value={message} className='border w-full px-3 py-2 outline-0 h-44' required></textarea>
                            </div>
                            <p className='py-2 font-bold' style={{color: 'red'}}>{ErrorEmpty}</p>
                            <button type='submit' onClick={handleSubmit} className='bg-primary px-9 py-3 text-white font-bold'>ارسل رسالة</button>
                        </form>
                    </div>
                </div>
            </div>
            <div className='pt-10 mt-10 bg-fixed relative bg-contact-place bg-center' style={{ backgroundImage: `url(${placeAddress})` }}>
                <div className='relative z-10'>
                    <h2 className='text-center text-4xl font-bold text-white' data-aos="fade-up">مقرنا</h2>
                    <p className='text-center font-bold pt-2 text-white' data-aos="fade-up">يمكنك زيارتنا في مقرنا في اي وقت تشاء</p>
                    <div className='w-4/5 m-auto flex flex-wrap mt-10 justify-center items-center gap-40 vsm:gap-10 sm:gap-10 md:gap-10 lg:gap-40'>
                        <div className='text-white' data-aos="fade-left">
                            <h4 className='font-extrabold text-7xl pb-4'>الرياض</h4>
                            <div className='mt-5'>
                                <div className='flex items-center pb-5'>
                                    <FaLocationDot className='ml-4' />
                                    <span>المملكة العريبة السعودية الرياض - ضرما - المنطقة الصناعية</span>
                                </div>
                                <div className='flex items-center pb-5'>
                                    <FaPhoneFlip className='ml-4' />
                                    <div>
                                        <span>996541177132+</span>
                                        <span className='px-5'>|</span>
                                        <span>996531442644+</span>
                                    </div>
                                </div>
                                <div className='flex items-center pb-5'>
                                    <FaEnvelope className='ml-4' />
                                    <span>info@granitewatani.com</span>
                                </div>
                            </div>
                        </div>
                        <div className='w-3/5 h-96 vsm:w-full sm:w-full md:w-full lg:w-3/5' data-aos="fade-left">
                            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d6100.733928992485!2d46.14487309847916!3d24.605822052506873!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e292bc38819de45%3A0x6f403b39a0e9fcdb!2z2YXYtdmG2Lkg2KzYsdin2YbZitiqINmI2LfZhtmKIFdhdGFuaSBHcmFuaXRlIEZhY3Rvcnk!5e0!3m2!1sen!2sma!4v1701867213454!5m2!1sen!2sma" style={{border:0}} className='w-full h-full' allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <Footer />
    </>
  )
}

export default Contact