import React, {useEffect, useState} from 'react'
import { FaCartShopping } from "react-icons/fa6";
import ProductData from '../data/ProductData';
import Header from '../Home/Header/Header'
import Footer from '../Home/Footer/Footer'
import { BiCart } from "react-icons/bi";

//animation
import AOS from 'aos'
import 'aos/dist/aos.css'
const Product = () => {
    const [AllProducts, setAllProducts] = useState([])
    const [product, setproduct] = useState([])
    const [isAdded, setisAdded] = useState(false)

    useEffect(()=>{
        if(!isAdded){
            const urlParams = new URLSearchParams(window.location.search);
            const id = urlParams.get('id');
            let dataProduct = []
            dataProduct.push(ProductData[id])
            setproduct(dataProduct)
            document.title = dataProduct[0].title + " - مصنع جرانيت وطني"
            setAllProducts(ProductData)
            setisAdded(true)
        }
    }, [isAdded])
    useEffect(() => {
        AOS.init({duration: 2000})
    })
    if(product.length <= 0){
        return(
            null
        )
    }

  return (
    <>
        <Header />
        <div className='w-4/5 m-auto'>
            <div className='flex flex-nowrap justify-center items-center gap-5 my-10 vsm:flex-wrap sm:flex-wrap md:flex-nowrap'>
                <div className='w-1/2 vsm:w-full sm:w-full md:w-1/2'>
                    <h1 className='text-5xl font-extrabold text-center mb-5' data-aos="fade-left">{product[0].title}</h1>
                    {
                        product[0].paragraph.map((data, index)=>(
                            <p key={index} className='font-semibold pb-5' data-aos="fade-left">{data.p}</p>
                        ))
                    }
                    <div className='flex justify-around flex-wrap vsm:gap-5 sm:gap-5 md:gap-0' data-aos="fade-left">
                        <table>
                            <tr>
                                <td className='font-medium pl-5'>الدولة :</td>
                                <td className='font-bold'>{product[0].country}</td>
                            </tr>
                            <tr>
                                <td className='font-medium pl-5'>النوع :</td>
                                <td className='font-bold'>{product[0].type === "granite"? 'جرانيت': 'الرخام'}</td>
                            </tr>
                            <tr>
                                <td className='font-medium pl-5'>الحجم :</td>
                                <td style={{ direction: 'ltr' }} className='font-bold'>{product[0].poi}</td>
                            </tr>
                        </table>
                        <div>
                            <ul className='list-disc'>
                                {
                                    product[0].details.map((data, index)=>(
                                        <li key={index} className='font-bold pb-2 text-primary'>{data.name}</li>
                                    ))
                                }
                            </ul>
                        </div>
                    </div>
                    <div>
                        <p></p>
                    </div>
                    <div className='m-auto mt-5 text-center reletive' data-aos="fade-up">
                        <a href={`/منتج/اتمام-الطلب/?id=${product[0].id}`} style={{backgroundColor: '#066a06'}} className='relative z-0 py-3  btn-contact text-center flex justify-center items-center'>
                            <button className='w-3/4 font-bold text-white relative z-10 flex items-center justify-center gap-5 '>أطلب الأن<span className='text-2xl'><BiCart /></span></button>
                        </a>
                    </div>
                </div>
                <div className='w-1/2 vsm:w-full sm:w-full md:w-1/2 vsm:mt-10 sm:mt-10 md:mt-0' data-aos="zoom-in">
                    <img src={product[0].image} alt={product[0].title} className='w-full h-96 object-cover' />
                </div>
            </div>
            <div className='mt-10' data-aos="fade-up">
                <h2 className='font-bold text-4xl mb-5'>منتجات ذات صلة</h2>
                <div className='flex flex-wrap justify-center items-start w-9/12 m-auto mt-10 gap-5 vsm:w-11/12 sm:w-11/12 md:w-11/12 lg:w-9/12'>
                    {
                        AllProducts.map((data, index)=>(
                            index <= 2 ? (
                                <div key={index} data-aos="zoom-in" className='cursor-pointer shadow-md mb-8 relative z-10 rounded-2xl w-1/5 vsm:w-4/5 sm:w-4/5 md:w-4/5 lg:w-1/5 hover-product'>
                                    <div className='relative'>
                                        <div className='w-full m-auto flex justify-center items-center rounded-t-2xl' style={{backgroundColor: '#00000030'}}>
                                            <img src={data.image} alt={data.title} className='h-56 w-full object-cover shadow-lg rounded-t-2xl vsm:h-36 sm:h-36 md:h-40 lg:h-56' />
                                        </div>
                                        <div className=''>
                                            <div className='border-b p-5 flex items-center justify-between flex-wrap'>
                                                <h2 className='text-center font-medium text-xl vsm:text-sm vsm:text-right sm:text-sm sm:text-right md:text-sm md:text-right lg:text-center lg:text-xl'>{data.title}</h2>
                                                <p  style={{ direction: 'ltr' }} className='font-medium text-xl vsm:text-sm vsm:text-right vsm:font-bold sm:font-bold sm:text-sm sm:text-right md:text-sm md:text-right lg:text-center lg:text-xl'>{data.poi === 'درج'? "": data.poi}</p>
                                            </div>
                                            <div className='flex justify-around items-center'>
                                                <p className='text-primary font-medium text-center text-xl py-5 w-3/5 border-l vsm:text-base sm:text-base md:text-xl'>{data.price} ر.س</p>
                                                <FaCartShopping className='text-xl w-1/4 cursor-pointer vsm:text-base sm:text-base md:text-xl' />
                                            </div>
                                        </div>
                                        <a className='text-primary font-bold' href={`/منتج/?id=${index}`}>
                                            <button className='btn-order scale-0 absolute z-10 border px-14 py-3 bg-white rounded-lg cursor-pointer w-4/5 text-base vsm:p-3 sm:p-3 md:p-3'>اطلب الان</button>
                                        </a>
                                    </div>
                                </div>
                            ) : (
                                null
                            )
                        ))
                    }
                </div>
            </div>
        </div>
        <Footer />
    </>
  )
}

export default Product