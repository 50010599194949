import React, { useState, useEffect } from 'react'
import Header from '../Home/Header/Header'
import Footer from '../Home/Footer/Footer'
import ProductData from '../data/ProductData';
//animation
import AOS from 'aos'
import 'aos/dist/aos.css'
const FormOrder = () => {
    let [AllProducts, setAllProducts] = useState([])
    let [product, setproduct] = useState([])
    let [isAdded, setisAdded] = useState(false)

    useEffect(()=>{
        if(!isAdded){
            const urlParams = new URLSearchParams(window.location.search);
            const id = urlParams.get('id');
            let dataProduct = []
            dataProduct.push(ProductData[id])
            setproduct(dataProduct)
            document.title = dataProduct[0].title + " - مصنع جرانيت وطني"
            setAllProducts(ProductData)
            setisAdded(true)
        }
    }, [isAdded])
    useEffect(() => {
        AOS.init({duration: 2000})
    })
    const [FullName, setFullName] = useState('')
    const [email, setemail] = useState('')
    const [numphone, setnumphone] = useState('')
    const [message, setmessage] = useState('')
    const [ErrorEmpty, setErrorEmpty] = useState('')
    const handleSubmit = async (e) => {
        e.preventDefault();
        if(FullName === '' || numphone === '' || message === ''){
            setErrorEmpty('يرجى إكمال جميع الحقول المطلوبة قبل إرسال النموذج.')
        } else {
            const formData = new FormData();
            formData.append('fullName', FullName);
            formData.append('Email', email);
            formData.append('Phone', numphone);
            formData.append('Number', message);
            formData.append('titleProduct', product[0].title);
            formData.append('Price', product[0].price);
            formData.append('from', "services");
            try{
                const response = await fetch('https://www.granitewatani.com/send-email.php', {
                    method: 'POST',
                    body: formData,
                });
                const json = await response.json();
                if (json.success) {
                  alert('تم إرسال الطلب بنجاح سوف يتم التواصل معكم في أقرب وقت ممكن')
                  setFullName('')
                  setemail('')
                  setmessage('')
                  setnumphone("")
                  setErrorEmpty('')
                } else {
                    console.log(json.success);
                }
            } catch(error){
                console.log(error)
            }
        }
    };
    if(product.length <= 0){
        return(
            null
        )
    }
  return (
    <>
        <Header />
        <div className='w-4/5 m-auto py-12'>
            <h2 className='text-center font-bold text-2xl mb-7' data-aos="fade-up">أدخل معلوماتك لإتمام الطلب</h2>
            <div className='flex gap-10 flex-nowrap vsm:flex-wrap sm:flex-wrap md:flex-wrap xl:flex-nowrap'>
                <div className='w-3/4 vsm:w-full sm:w-full md:w-full xl:w-3/4' data-aos="fade-left">
                    <form method='POST'>
                        <div className='flex flex-col mb-5'>
                            <label className='font-semibold' for="name">الاسم الكامل<span className='pr-2' style={{ color: 'red' }}>*</span></label>
                            <input type='text' name='name' id='name' onChange={(e)=> setFullName(e.target.value)} value={FullName} className='mt-2 border py-2 px-5 outline-0' required/>
                        </div>
                        <div className='flex flex-col mb-5'>
                            <label className='font-semibold' for="numphone">رقم الهاتف<span className='pr-2' style={{ color: 'red' }}>*</span></label>
                            <input type='text' name='numphone' id='numphone' onChange={(e)=> setnumphone(e.target.value)} value={numphone} className='mt-2 border py-2 px-5 outline-0' pattern='[0-9]+' required/>
                        </div>
                        <div className='flex flex-col mb-5'>
                            <label className='font-semibold' for="email">البريد الإلكتروني</label>
                            <input type="email" name='email' id='email' onChange={(e)=> setemail(e.target.value)} value={email} className='mt-2 border py-2 px-5 outline-0' />
                        </div>
                        <div className='flex flex-col mb-5'>
                            <label className='font-semibold' for="">الرسالة<span className='pr-2' style={{ color: 'red' }}>*</span></label>
                            <textarea id='message' name='message' onChange={(e)=> setmessage(e.target.value)} value={message} className='mt-2 border py-2 px-5 outline-0 h-36' required></textarea>
                        </div>
                        <p className='py-2 font-bold' style={{color: 'red'}}>{ErrorEmpty}</p>
                        <button type='submit' onClick={handleSubmit} className='bg-primary text-white py-3 px-7 font-bold'>إرسال الطلب</button>
                    </form>
                </div>
                <div className='w-1/4 vsm:w-full sm:w-full md:w-full xl:w-1/4' data-aos="fade-left">
                    <h2 className='font-bold text-xl pb-5 text-center'>معلومات المنتج</h2>
                    <div>
                        <div className='flex gap-5 items-center'>
                            <img src={product[0].image} alt={product[0].title} className='w-full h-56 object-cover' />
                            
                        </div>
                        <div className='text-center mt-9'>
                            <h2 className='font-bold mb-2'>{product[0].title}</h2>
                            <div className='flex justify-center text-xl'>
                                <h2 className='font-bold pl-5'>الثمن: </h2>
                                <p className='font-bold text-primary'>{product[0].price} ر.س</p>
                            </div>
                            <div className='flex justify-center text-xl'>
                                <h2 className='font-bold pl-5'>الحجم: </h2>
                                <p  style={{ direction: 'ltr' }} className='font-bold text-primary'>{product[0].poi}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <Footer />
    </>
  )
}

export default FormOrder