import React, { useEffect } from 'react'
import Header from '../Home/Header/Header'
import Footer from '../Home/Footer/Footer'
import { 
    FaCircleUser,
    FaStar,
    FaRegStar
} from "react-icons/fa6";
import imgPlace from '../../Assets/watani-place.jpeg'
import vision from '../../Assets/vision.jpg'
import plan from '../../Assets/plan.jpg'
import work from '../../Assets/work1.jpg'
import work2 from '../../Assets/work2.jpg'
import work3 from '../../Assets/work3.jpg'
import work4 from '../../Assets/work4.jpeg'
import work5 from '../../Assets/work5.jpeg'

import textureGranite from '../../Assets/texture-granite-about.jpg'
import './About.css'
//animation
import AOS from 'aos'
import 'aos/dist/aos.css'
const About = () => {
    const opinion = [
        {
            id: 0,
            name: 'عبد الله',
            stars: 5,
            description: '“احترافية كبيرة في التعامل مع الزبناء وجودة عالية للمنتوجات والخدمات“'
        },
        {
            id: 1,
            name: 'ريان الجعيدي',
            stars: 5,
            description: '“تعامل رائع واسلوب محترم ما شاء الله.“ '
        },
        {
            id: 2,
            name: 'حسام',
            stars: 5,
            description: '“بائعون ذوو خبرة عالية ومجموعة كبيرة جدًا من المنتجات المختلفة.“'
        },
        
    ]
    const ourWorks = [
        {
            id: 0,
            img: work
        },
        {
            id: 1,
            img: work2
        },
        {
            id: 2,
            img: work3
        },
        {
            id: 3,
            img: work4
        },
        {
            id: 4,
            img: work5
        },
    ]

    useEffect(() => {
      document.title = 'من نحن - مصنع جرانيت وطني'
    })
    useEffect(() => {
        AOS.init({duration: 2000})
    })
  return (
    <>
        <Header />
        <div className='py-10 pb-0 bg-cover relative bg-about-p' style={{ backgroundImage: `url(${textureGranite})` }}>
            <div className='relative z-10'>
                <h1 className='text-center font-bold text-5xl mb-3' data-aos="fade-up">من نحن</h1>
                <p className='text-center font-normal text-2xl mb-10' data-aos="fade-up">تعرف اكثر على شركة وطني للرخام والجرانيت </p>
                <section className='w-4/5 m-auto flex flex-nowrap items-center justify-center gap-10 mb-6 vsm:flex-wrap sm:flex-wrap md:flex-wrap lg:flex-nowrap'>
                    <div className='w-1/2 vsm:w-4/5 sm:w-4/5 md:w-4/5 lg:w-1/2' data-aos="fade-left">
                        <h4 className='font-bold text-4xl mb-5'>شركة جرانيت وطني</h4>
                        <p className='font-medium text-lg'>شركة جرانيت وطني شركة سعودية ذات مسؤولية محدودة و مقرها الرئيسي المملكة العربية السعودية، مدينة الرياض.</p>
                        <p className='font-medium text-lg'>يمتلك مصنع وطني خبرة في الصناعات الثقيلة استمرت لاكثر من 15 سنة في صناعات الجرانيت، ويعتبر مصنع وطني ثالث اكبر مصنع في المملكة من بين 15 مصنع متواجد في انحاء المملكة العربية السعودية.</p>
                    </div>
                    <div className='w-1/2 vsm:w-4/5 sm:w-4/5 md:w-4/5 lg:w-1/2' data-aos="fade-left">
                        <img src={imgPlace} alt='WataniGranite' className='h-96 object-cover' />
                    </div>
                </section>
                <section className='bg-primary vsm:pb-10 sm:pb-10 md:pb-10 lg:pb-0'>
                    <div className='m-auto flex items-center gap-10  vsm:flex-wrap sm:flex-wrap md:flex-wrap lg:flex-nowrap' style={{maxWidth: '1995px'}}>
                        <div className='w-1/2 vsm:w-full sm:w-full md:w-full lg:w-1/2'>
                            <img src={vision} alt='WataniGranite' className='h-full object-cover' />
                        </div>
                        <div className='w-1/2 vsm:w-4/5 sm:w-4/5 md:w-4/5 lg:w-1/2 vsm:m-auto sm:m-auto md:m-auto lg:m-0' data-aos="fade-left">
                            <h4 className='font-bold text-4xl mb-5'>رؤية الشركة</h4>
                            <p className='font-medium text-lg w-4/5 vsm:w-full sm:w-full md:w-4/5'>تسعى شركة جرانيت وطني للرخام و الجرانيت الى ان تكون احدى الشركات الرائدة في المملكة العربية السعودية و منطقة الرق الاوسط من خلال تقديم اعلى مستويات التميز و الاحترافية و الجودة الادارية في مجال الجرانيت و الرخام</p>
                        </div>
                    </div>
                </section>
                <section className='bg-white py-9'>
                    <div className='w-4/5 m-auto flex items-center justify-center gap-10 vsm:flex-wrap sm:flex-wrap md:flex-wrap lg:flex-nowrap'>
                        <div className='w-1/2 vsm:w-4/5 sm:w-4/5 md:w-4/5 lg:w-1/2' data-aos="fade-left">
                            <h4 className='font-bold text-4xl mb-5'>رسالة جرانيت وطني</h4>
                            <p className='font-medium text-lg'>شركة جرانيت وطني شركة سعودية ذات مسؤولية محدودة و مقرها الرئيسي المملكة العربية السعودية، مدينة الرياض.</p>
                            <p className='font-medium text-lg'>يمتلك مصنع وطني خبرة في الصناعات الثقيلة استمرت لاكثر من 15 سنة في صناعات الجرانيت، ويعتبر مصنع وطني ثالث اكبر مصنع في المملكة من بين 15 مصنع متواجد في انحاء المملكة العربية السعودية.</p>
                        </div>
                        <div className='w-1/2 vsm:w-4/5 sm:w-4/5 md:w-4/5 lg:w-1/2' data-aos="fade-left">
                            <img src={plan} alt='WataniGranite' className='h-96 object-cover'  />
                        </div>
                    </div>
                </section>
                <section className='bg-primary'>
                    <div className='w-4/5 m-auto py-12'>
                        <h4 className='font-bold text-4xl mb-7 text-center' data-aos="fade-up">اراء عملائنا</h4>
                        <div className='flex justify-center gap-10 flex-nowrap vsm:flex-wrap sm:flex-wrap md:flex-wrap lg:flex-nowrap'>
                            {
                                opinion.map((data, index)=>(
                                    <div key={index} className='bg-white w-2/6 p-9 vsm:w-full sm:w-full md:w-3/4 lg:w-2/6' data-aos="zoom-in">
                                        <div className='flex items-center gap-5 mb-5'>
                                            <FaCircleUser className='text-4xl text-primary' />
                                            <div className='flex flex-col'>
                                                <p>{data.name}</p>
                                                <div className='flex'>
                                                    {
                                                      Array(data.stars).fill().map((_, index) => (
                                                        <FaStar style={{color: '#CF6C11'}} />
                                                      ))
                                                    }
                                                    {
                                                      Array(5 - data.stars).fill().map((_, index) => (
                                                        <FaRegStar style={{color: '#CF6C11'}} />
                                                      ))
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                        <p>{data.description}</p>
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                </section>
                <section className='bg-white py-10'>
                    <div className='w-4/5 m-auto'>
                        <h4 className='font-bold text-4xl mb-7 text-center' data-aos="fade-up">صور للجرانيت</h4>
                        <div className='flex justify-center gap-7 flex-nowrap vsm:flex-wrap sm:flex-wrap md:flex-wrap lg-flex-nowrap'>
                            {
                                ourWorks.map((data, index)=>(
                                    <div key={index}>
                                        <img src={data.img} alt='مشاريع الوطني للجرانيت' className='h-96 w-full object-cover vsm:w-full sm:w-full md:w-full lg:w-full'  />
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                </section>
            </div>
        </div>
        <Footer />
    </>
  )
}

export default About