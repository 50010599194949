const ProjectsData = [
    {
        id: 0,
        title: 'جامعة الاميرة نورة - الرياض',
        description: "من أميز المشاريع التي نعمل عليها مشاريع المساجد و الجوامع حيث تتميز بطارز إسلامي رائع ، وكان جامع الصالح بصنعاء من ضمن تلك المشاريع.",
        img: require("../../Assets/project01.jpg"),
        url: 'https://www.pnu.edu.sa/ar/Pages/home.aspx'
    },
    {
        id: 1,
        title: 'جامعة الامام محمد بن سعود',
        description: "من أميز المشاريع التي نعمل عليها مشاريع المساجد و الجوامع حيث تتميز بطارز إسلامي رائع ، وكان جامع الصالح بصنعاء من ضمن تلك المشاريع.",
        img: require("../../Assets/project02.jpg"),
        url: 'https://www.pnu.edu.sa/ar/Pages/home.aspx'
    },
    {
        id: 2,
        title: 'جامعة ام القرى - الرياض',
        description: "من أميز المشاريع التي نعمل عليها مشاريع المساجد و الجوامع حيث تتميز بطارز إسلامي رائع ، وكان جامع الصالح بصنعاء من ضمن تلك المشاريع.",
        img: require("../../Assets/project03.jpg"),
        url: 'https://uqu.edu.sa/'
    },
    {
        id: 3,
        title: 'جامعة حائل - حائل',
        description: "من أميز المشاريع التي نعمل عليها مشاريع المساجد و الجوامع حيث تتميز بطارز إسلامي رائع ، وكان جامع الصالح بصنعاء من ضمن تلك المشاريع.",
        img: require("../../Assets/project04.jpg"),
        url: 'https://www.uoh.edu.sa/'
    },
    {
        id: 4,
        title: 'مركز الملك عبدالله للدراسات البترولية',
        description: "من أميز المشاريع التي نعمل عليها مشاريع المساجد و الجوامع حيث تتميز بطارز إسلامي رائع ، وكان جامع الصالح بصنعاء من ضمن تلك المشاريع.",
        img: require("../../Assets/project05.jpg"),
        url: 'https://www.kapsarc.org/ar/'
    },
    {
        id: 5,
        title: 'قطار المشاعر - مكة المكرمة',
        description: "من أميز المشاريع التي نعمل عليها مشاريع المساجد و الجوامع حيث تتميز بطارز إسلامي رائع ، وكان جامع الصالح بصنعاء من ضمن تلك المشاريع.",
        img: require("../../Assets/project06.png"),
        url: '#'
    },
]

export default ProjectsData;