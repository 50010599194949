import React from 'react'
import logo from '../../../Assets/logo.png'
import { 
    FaLocationDot,
    FaPhoneFlip,
    FaEnvelope,
    FaFacebookF,
    FaSquareXTwitter,
    FaSnapchat,
    FaTiktok,
} from "react-icons/fa6";

const Footer = () => {
    const speedLink = [
        {
            id: 0,
            name: 'الرئيسية',
            url: '/'
        },
        {
            id: 1,
            name: 'المنتجات',
            url: "/المنتجات"
        },
        {
            id: 2,
            name: 'المشاريع',
            url: "/مشاريعنا"
        },
        {
            id: 3,
            name: 'شهاداتنا',
            url: '/#success-partners'
        },
        {
            id: 4,
            name: 'تواصل معنا',
            url: "/تواصل-معنا"
        },
    ]
  return (
    <footer className='bg-second text-white py-10'>
        <div className='flex flex-wrap justify-around w-4/5 m-auto vsm:justify-start sm:justify-start md:justify-start lg:justify-around'>
            <div className='w-1/5 vsm:w-full vsm:mb-9 sm:w-full sm:mb-9 md:w-full md:mb-9 lg:w-1/5 lg:mb-0'>
                <img src={logo} alt="Watani Granite" className='w-32' />
                <p className='mt-5'>شركة جرانيت وطني هي شركة متخصصة في مجال تزويد و تصنيع الجرانيت في المملكة العربية السعودية</p>
            </div>
            <div className='vsm:hidden sm:hidden md:hidden lg:block'>
                <h4 className='text-primary font-bold'>روابط سريعة</h4>
                <ul className='mt-5'>
                    {
                        speedLink.map((data, index)=>(
                            <li key={index} className='pr-5 pb-5'>
                                <a href={data.url}>{data.name}</a>
                            </li>
                        ))
                    }
                </ul>
            </div>
            <div className='vsm:mb-9 sm:mb-9 md:mb-9 lg:mb-0'>
                <h4 className='text-primary font-bold'>معلومات التواصل</h4>
                <div className='mt-5'>
                    <div className='flex items-center pb-5'>
                        <FaLocationDot className='ml-4' />
                        <span>المملكة العريبة السعودية الرياض - ضرما - المنطقة الصناعية</span>
                    </div>
                    <div className='flex items-center pb-5'>
                        <FaPhoneFlip className='ml-4' />
                        <span>996541177132+</span>
                    </div>
                    <div className='flex items-center pb-5'>
                        <FaEnvelope className='ml-4' />
                        <span>info@granitewatani.com</span>
                    </div>
                </div>
            </div>
            <div className='vsm:mb-9 sm:mb-9 md:mb-9 lg:mb-0'>
                <h4 className='text-primary font-bold'>يمكن متابعتنا على</h4>
                <div className='flex flex-wrap mt-5'>
                    <a href='https://web.facebook.com/profile.php?id=61554284999789' target='_blanck'>
                        <FaFacebookF  className='ml-5 transition duration-120 delay-150 ease-in-out hover:text-facebook hover:delay-500' />
                    </a>
                    <a href='https://twitter.com/FactoryWatani' target='_blanck'>
                        <FaSquareXTwitter  className='ml-5' />
                    </a>
                    <a href='#2'>
                        <FaSnapchat  className='ml-5 transition duration-120 delay-150 ease-in-out hover:text-snapchat hover:delay-500' />
                    </a>
                    <a href='https://www.tiktok.com/@watani.granite.factory' target='_blanck'>
                        <FaTiktok  className='transition duration-120 delay-150 ease-in-out hover:text-tiktok hover:delay-500' />
                    </a>
                </div>
            </div>
        </div>
        <div className='border-t mt-5'>
            <p className='text-center pt-10 ' style={{color: '#7A7A7A'}}>© جميع الحقوق محفوظة لشركة وطني للرخام و الجرانيت</p>
        </div>
    </footer>
  )
}

export default Footer