import React, { useState, useEffect } from 'react'
import ProjectsData from '../data/ProjectsData';
import Header from '../Home/Header/Header'
import Footer from '../Home/Footer/Footer'
import textureGranite from '../../Assets/texture-granite-about.jpg'
import { FaArrowLeftLong } from "react-icons/fa6";
//animation
import AOS from 'aos'
import 'aos/dist/aos.css'

const Projects = () => {
    const [projects, setprojects] = useState(ProjectsData)
    useEffect(()=>{
        document.title = 'مشاريعنا - مصنع جرانيت وطني'
    })
    useEffect(() => {
        AOS.init({duration: 2000})
    })
  return (
    <>
        <Header />
        <div className='py-10 pb-0 bg-cover relative bg-about-p' style={{ backgroundImage: `url(${textureGranite})` }}>
            <div className='relative z-10'>
                <div>
                    <h2 className='text-center font-bold text-5xl mb-3' data-aos="fade-up">مشاريعنا</h2>
                    <h5 className='text-center font-normal text-2xl' data-aos="fade-up">تفتخر شركة وطني بالعمل مع عدة مشاريع وتزويدها</h5>
                    <div className='flex flex-col justify-center items-start w-9/12 m-auto py-10 gap-5 vsm:w-11/12 sm:w-11/12 md:w-11/12 lg:w-9/12'>
                        {
                            projects.map((data, index)=>(
                                index%2 === 0? (
                                    <div data-aos="fade-up" className='bg-primary flex flex-nowrap flex-row justify-center items-center vsm:flex-wrap sm:flex-wrap md:flex-wrap lg:flex-nowrap'>
                                        <div className='w-1/2 vsm:w-full sm:w-full md:w-full xl:w-1/2'>
                                            <img src={data.img} alt={data.title} className='h-80 object-cover vsm:w-full sm:w-full md:w-full xl:w-full' />
                                        </div>
                                        <div className='h-full w-1/2 vsm:w-full sm:w-full md:w-full xl:w-1/2 flex flex-col gap-7 items-start p-6'>
                                            <h2 className='font-bold text-2xl '>{data.title}</h2>
                                            <p className='text-lg'>{data.description}</p>
                                            <a href={data.url} target='_blanck'>
                                                <button className='flex flex-row items-center gap-5 bg-white px-6 py-3 text-primary rounded-sm'>اعرف عنا اكثر <FaArrowLeftLong /></button>
                                            </a>
                                        </div>
                                    </div>
                                ):(
                                    <div data-aos="fade-up" className='bg-second flex flex-nowrap flex-row justify-center items-center vsm:flex-wrap sm:flex-wrap md:flex-wrap lg:flex-nowrap'>
                                        <div className='w-1/2 vsm:w-full sm:w-full md:w-full xl:w-1/2'>
                                            <img src={data.img} alt={data.title} className='h-80 object-cover vsm:w-full sm:w-full md:w-full xl:w-full' />
                                        </div>
                                        <div className='h-full w-1/2 vsm:w-full sm:w-full md:w-full xl:w-1/2 flex flex-col gap-7 items-start p-6'>
                                            <h2 className='font-bold text-2xl text-white'>{data.title}</h2>
                                            <p className='text-lg text-white'>{data.description}</p>
                                            <a href={data.url} target='_blanck'>
                                                <button className='flex flex-row items-center gap-5 bg-white px-6 py-3 text-second rounded-sm'>اعرف عنا اكثر <FaArrowLeftLong /></button>
                                            </a>
                                        </div>
                                    </div>
                                )
                            ))
                        }
                    </div>
                </div>
            </div>
        </div>
        <Footer />
    </>
  )
}

export default Projects